import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Box mt={10} mb={15}>
    <Grid container spacing={2} justify="center" alignItems="center" className="boxshad">
      <Grid item xs={12}>
        <Box align='center'>
      <Typography  gutterBottom variant="h1" style={{ color:'green' }}><b>404</b></Typography>
    <p>Unfortunately the page you are looking for has been moved or deleted</p>
    <Link to="/">
    <Button variant="contained" color="primary">
        Go to home page
      </Button>
      </Link>
      </Box>
        </Grid>
        </Grid>
        </Box>
    </Container>
    
  </Layout>
)

export default NotFoundPage
